<template>
  <nav
    class="pcoded-navbar menu-light menu-backoffice"
    :class="{
      'mobile-open': isSidebarOpen,
    }"
  >
    <div class="navbar-wrapper">
      <div class="navbar-content">
        <div class="navbar-brand header-logo">
          <a href="javascript:void(0)" class="b-brand ms-4">
            <img :src="logo" class="img-fluid" style="width: 90px" />
          </a>
          <a class="mobile-menu on" href="javascript:void(0)">
            <span></span>
          </a>
        </div>

        <div class="btn-pos">
          <button
            type="button"
            class="btn btn-sm btn-primary w-100"
            @click="pos"
            :disabled="isDisableMenu"
          >
            <img src="@/assets/icon/masuk-pos.svg" alt="Icon Masuk POS" />
            <span class="pcoded-mtext ms-1"> {{ $t('message.goToPOS') }}</span>
          </button>
        </div>

        <ul class="nav pcoded-inner-navbar" style="font-size: 15px">
          <li
            :class="
              $route.name === 'dashboard' || currentRoute === 'Dashboard'
                ? 'nav-item active'
                : 'nav-item'
            "
          >
            <router-link to="/" class="nav-link pb-1 router-link">
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9.02 2.84L3.63 7.04C2.73 7.74 2 9.23 2 10.36V17.77C2 20.09 3.89 21.99 6.21 21.99H17.79C20.11 21.99 22 20.09 22 17.78V10.5C22 9.29 21.19 7.74 20.2 7.05L14.02 2.72C12.62 1.74 10.37 1.79 9.02 2.84Z"
                  />
                  <path d="M12 17.99V14.99" />
                </svg>
              </span>
              <span class="pcoded-mtext">{{ $t('message.dashboard') }}</span>
            </router-link>
          </li>
          <li
            :class="
              currentRoute === 'transaction' ||
              currentRoute === 'list_transaction' ||
              currentRoute === 'detail_transaction'
                ? 'nav-item active'
                : 'nav-item'
            "
          >
            <router-link to="/transaction" class="nav-link pb-1 router-link">
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
                  />
                  <path
                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                  />
                  <path d="M2 9H3C6 9 7 8 7 5V4" />
                  <path d="M22 9H21C18 9 17 8 17 5V4" />
                  <path d="M2 15H3C6 15 7 16 7 19V20" />
                  <path d="M22 15H21C18 15 17 16 17 19V20" />
                </svg>
              </span>
              <span class="pcoded-mtext">{{ $t('message.transaction') }}</span>
            </router-link>
          </li>
          <li
            :class="
              $route.name === 'index_product_list' ||
              $route.name === 'detail_product' ||
              $route.name === 'add_product' ||
              $route.name === 'edit_product' ||
              $route.name === 'bahan' ||
              $route.name === 'promosi' ||
              $route.name === 'barcode' ||
              $route.name === 'pengaturan' ||
              $route.name === 'import_product' ||
              $route.name === 'view_product'
                ? 'nav-item active'
                : 'nav-item'
            "
          >
            <router-link to="/product" class="nav-link pb-1 router-link">
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M7.5 7.67V6.7C7.5 4.45 9.31 2.24 11.56 2.03C14.24 1.77 16.5 3.88 16.5 6.51V7.89"
                  />
                  <path
                    d="M8.99999 22H15C19.02 22 19.74 20.39 19.95 18.43L20.7 12.43C20.97 9.99 20.27 8 16 8H7.99999C3.72999 8 3.02999 9.99 3.29999 12.43L4.04999 18.43C4.25999 20.39 4.97999 22 8.99999 22Z"
                  />
                  <path
                    d="M15.4955 12H15.5045"
                    stroke="currentColor"
                    stroke-width="2"
                  />
                  <path
                    d="M8.49451 12H8.50349"
                    stroke="currentColor"
                    stroke-width="2"
                  />
                </svg>
              </span>
              <span class="pcoded-mtext">{{ $t('message.product') }}</span>
            </router-link>
          </li>
          <li
            :class="
              currentRoute === 'inventory' ||
              currentRoute === 'daftar_stok' ||
              currentRoute === 'list_stok' ||
              currentRoute === 'riwayat_perubahan' ||
              currentRoute === 'stok_keluar' ||
              currentRoute === 'list_stok_keluar' ||
              currentRoute === 'detail_mark_out' ||
              currentRoute === 'detail_transfer_out' ||
              currentRoute === 'kurangi_manual' ||
              currentRoute === 'transfer_keluar' ||
              currentRoute === 'stok_masuk' ||
              currentRoute === 'list_stok_masuk' ||
              currentRoute === 'tambah_manual' ||
              currentRoute === 'detail_stok_masuk' ||
              currentRoute === 'detail_transfer_in' ||
              currentRoute === 'accept_transfer' ||
              currentRoute === 'stok_opname' ||
              currentRoute === 'list_stok_opname' ||
              currentRoute === 'opname_manual' ||
              currentRoute === 'detail_opname' ||
              currentRoute === 'import_opname'
                ? 'nav-item active'
                : 'nav-item'
            "
          >
            <router-link to="/inventory" class="nav-link pb-1 router-link">
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M3.17001 7.44L12 12.55L20.77 7.46997" />
                  <path d="M12 21.61V12.54" />
                  <path
                    d="M9.92999 2.48L4.59 5.45003C3.38 6.12003 2.39001 7.80001 2.39001 9.18001V14.83C2.39001 16.21 3.38 17.89 4.59 18.56L9.92999 21.53C11.07 22.16 12.94 22.16 14.08 21.53L19.42 18.56C20.63 17.89 21.62 16.21 21.62 14.83V9.18001C21.62 7.80001 20.63 6.12003 19.42 5.45003L14.08 2.48C12.93 1.84 11.07 1.84 9.92999 2.48Z"
                  />
                  <path d="M17 13.24V9.58002L7.51001 4.09998" />
                </svg>
              </span>
              <span class="pcoded-mtext">{{ $t('message.inventory') }}</span>
            </router-link>
          </li>
          <li
            :class="
              currentRoute === 'suppliers' ||
              currentRoute === 'supplier_list' ||
              currentRoute === 'list_purchase_product' ||
              currentRoute === 'purchase_order' ||
              currentRoute === 'detail_purchase_order' ||
              currentRoute === 'list_retur_product' ||
              currentRoute === 'create_retur' ||
              currentRoute === 'detail_retur'
                ? 'nav-item active'
                : 'nav-item'
            "
          >
            <router-link to="/suppliers" class="nav-link pb-1 router-link">
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle
                    cx="10.0048"
                    cy="19.5606"
                    r="2"
                    transform="rotate(-15 10.0048 19.5606)"
                    stroke="currentColor"
                  />
                  <path
                    d="M11.9366 19.043L21.5959 16.4548"
                    stroke="currentColor"
                  />
                  <path
                    d="M9.48717 17.6288L6.38134 6.03769C5.99311 4.5888 4.89778 4.36466 3.93185 4.62348C2.96593 4.88229 2.12941 5.62408 2.51764 7.07296"
                    stroke="currentColor"
                  />
                  <rect
                    x="9.08966"
                    y="8.41785"
                    width="9"
                    height="9"
                    transform="rotate(-15 9.08966 8.41785)"
                    stroke="currentColor"
                  />
                  <path
                    d="M11.9874 7.64136L14.8852 6.8649L16.1793 11.6945L14.5147 11.2778L13.2815 12.471L11.9874 7.64136Z"
                    stroke="currentColor"
                  />
                </svg>
              </span>
              <span class="pcoded-mtext">{{ $t('message.supplier') }}</span>
            </router-link>
          </li>
          <li
            :class="
              currentRoute === 'table_management' ||
              currentRoute === 'list_area'
                ? 'nav-item active'
                : 'nav-item'
            "
            hidden
          >
            <router-link
              to="/table_management"
              class="nav-link pb-1 router-link"
            >
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect x="3" y="4" width="18" height="2" />
                  <path d="M12 6V20" />
                  <path d="M7 20.5H6V20H18V20.5H17" />
                </svg>
              </span>
              <span class="pcoded-mtext">{{
                $t('message.tableManagement')
              }}</span>
            </router-link>
          </li>
          <li
            :class="
              currentRoute === 'promotion' ? 'nav-item active' : 'nav-item'
            "
          >
            <router-link to="/promotion" class="nav-link pb-1 router-link">
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M3.64364 13.4883C3.6893 13.3754 3.76721 13.2805 3.85978 13.2014L12.1867 6.08353C12.6893 5.65382 13.4632 5.82168 13.7427 6.42104L16.8753 13.1388C17.1491 13.7259 16.8008 14.4145 16.1656 14.5419L5.50173 16.6802C5.37159 16.7063 5.23725 16.7071 5.11128 16.6653C4.75939 16.5484 4.01645 16.2171 3.64665 15.424C3.27102 14.6185 3.50775 13.8243 3.64364 13.4883Z"
                  />
                  <path
                    d="M6.20925 11.2589C5.08835 12.2153 6.89039 16.2068 8.45348 16.0716"
                  />
                  <path d="M18.5166 8.48285L20.7824 7.4263" />
                  <path d="M16.5538 6.63967L17.7325 4.43498" />
                  <path d="M21.1134 11.6855L18.6669 11.1712" />
                  <path
                    d="M6.70618 16.6495C6.88068 18.1402 8.1481 19.2969 9.68568 19.2969C11.3425 19.2969 12.6857 17.9538 12.6857 16.2969C12.6857 16.0113 12.6458 15.7351 12.5712 15.4734L11.0906 15.7703C11.1521 15.9341 11.1857 16.1116 11.1857 16.2969C11.1857 17.1254 10.5141 17.7969 9.68568 17.7969C8.87589 17.7969 8.21598 17.1552 8.18669 16.3526L6.70618 16.6495Z"
                  />
                </svg>
              </span>
              <span class="pcoded-mtext">{{ $t('message.promotion') }}</span>
            </router-link>
          </li>
          <li
            :class="
              currentRoute === 'reports' ||
              currentRoute === 'penjualan' ||
              currentRoute === 'metode_bayar' ||
              currentRoute === 'list_kasir' ||
              currentRoute === 'laba_rugi' ||
              currentRoute === 'retur_penjualan' ||
              currentRoute === 'pelanggan' ||
              currentRoute === 'list_produk' ||
              currentRoute === 'pajak' ||
              currentRoute === 'list_shift' ||
              currentRoute === 'detail_shift' ||
              currentRoute === 'list_utang'
                ? 'nav-item active'
                : 'nav-item'
            "
          >
            <router-link to="/reports" class="nav-link pb-1 router-link">
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M6.88 18.15V16.08" stroke-linecap="round" />
                  <path d="M12 18.15V14.01" stroke-linecap="round" />
                  <path d="M17.12 18.15V11.93" stroke-linecap="round" />
                  <path
                    d="M17.12 5.85L16.66 6.39C14.11 9.37 10.69 11.48 6.88 12.43"
                    stroke-linecap="round"
                  />
                  <path d="M14.19 5.85H17.12V8.77" />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  />
                </svg>
              </span>
              <span class="pcoded-mtext">{{ $t('message.report') }}</span>
            </router-link>
          </li>
          <li
            :class="
              currentRoute === 'operasional' ? 'nav-item active' : 'nav-item'
            "
          >
            <router-link to="/operasional" class="nav-link pb-1 router-link">
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12.37 2.15003L21.37 5.75C21.72 5.89 22 6.31 22 6.68V10C22 10.55 21.55 11 21 11H3C2.45 11 2 10.55 2 10V6.68C2 6.31 2.28 5.89 2.63 5.75L11.63 2.15003C11.83 2.07003 12.17 2.07003 12.37 2.15003Z"
                  />
                  <path
                    d="M22 22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19V22Z"
                  />
                  <path d="M4 18V11" />
                  <path d="M8 18V11" />
                  <path d="M12 18V11" />
                  <path d="M16 18V11" />
                  <path d="M20 18V11" />
                  <path d="M1 22H23" />
                  <path
                    d="M12 8.5C12.8284 8.5 13.5 7.82843 13.5 7C13.5 6.17157 12.8284 5.5 12 5.5C11.1716 5.5 10.5 6.17157 10.5 7C10.5 7.82843 11.1716 8.5 12 8.5Z"
                  />
                </svg>
              </span>
              <span class="pcoded-mtext">{{ $t('message.operational') }}</span>
            </router-link>
          </li>
          <li
            :class="
              currentRoute === 'customers' ? 'nav-item active' : 'nav-item'
            "
          >
            <router-link to="/customers" class="nav-link pb-1 router-link">
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9.16 11.87C9.06 11.86 8.94 11.86 8.83 11.87C6.45 11.79 4.56 9.84 4.56 7.44C4.56 4.99 6.54 3 9 3C11.45 3 13.44 4.99 13.44 7.44C13.43 9.84 11.54 11.79 9.16 11.87Z"
                    stroke="currentColor"
                  />
                  <path
                    d="M15.6374 5C17.5774 5 19.1374 6.57 19.1374 8.5C19.1374 10.39 17.6374 11.93 15.7674 12C15.6874 11.99 15.5974 11.99 15.5074 12"
                    stroke="currentColor"
                  />
                  <path
                    d="M16 21C16 17.13 12.8626 14 9 14C5.13737 14 2 17.13 2 21"
                    stroke="currentColor"
                  />
                  <path
                    d="M21.6022 21C21.6022 17.573 19.1734 14.5258 15.5075 14.5258"
                    stroke="currentColor"
                  />
                </svg>
              </span>
              <span class="pcoded-mtext">{{ $t('message.customer') }}</span>
            </router-link>
          </li>
          <li
            :class="
              is_route_match('staff', currentRoute)
                ? 'nav-item active'
                : 'nav-item'
            "
            v-if="
              can_access(
                get_user_data.permission,
                'Staff',
                get_user_data.role_id
              )
            "
          >
            <router-link to="/staff" class="nav-link pb-1 router-link">
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                    stroke="currentColor"
                  />
                  <path
                    d="M3.41 22C3.41 18.13 7.26 15 12 15C12.9773 15 13.9168 15.1331 14.7925 15.3783"
                    stroke="currentColor"
                  />
                  <path
                    d="M13 17C13 16.4477 13.4477 16 14 16L21.5 16C22.0523 16 22.5 16.4477 22.5 17V21C22.5 21.5523 22.0523 22 21.5 22H14C13.4477 22 13 21.5523 13 21L13 17Z"
                    stroke="currentColor"
                  />
                  <path d="M20 17V14.5" stroke="currentColor" />
                  <path d="M15.5 17V14.5" stroke="currentColor" />
                </svg>
              </span>
              <span class="pcoded-mtext">Staff</span>
            </router-link>
          </li>
          <li
            :class="
              is_route_match('settings', currentRoute) ||
              $route.name === 'daftar_metode' ||
              $route.name === 'riwayat_transaksi'
                ? 'nav-item active'
                : 'nav-item'
            "
          >
            <router-link to="/settings" class="nav-link pb-1 router-link">
              <span class="pcoded-micon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                    stroke="currentColor"
                  />
                  <path
                    d="M2 12.88V11.12C2 10.08 2.85 9.22 3.9 9.22C5.71 9.22 6.45 7.94 5.54 6.37C5.02 5.47 5.33 4.3 6.24 3.78L7.97 2.79C8.76 2.32 9.78 2.6 10.25 3.39L10.36 3.58C11.26 5.15 12.74 5.15 13.65 3.58L13.76 3.39C14.23 2.6 15.25 2.32 16.04 2.79L17.77 3.78C18.68 4.3 18.99 5.47 18.47 6.37C17.56 7.94 18.3 9.22 20.11 9.22C21.15 9.22 22.01 10.07 22.01 11.12V12.88C22.01 13.92 21.16 14.78 20.11 14.78C18.3 14.78 17.56 16.06 18.47 17.63C18.99 18.54 18.68 19.7 17.77 20.22L16.04 21.21C15.25 21.68 14.23 21.4 13.76 20.61L13.65 20.42C12.75 18.85 11.27 18.85 10.36 20.42L10.25 20.61C9.78 21.4 8.76 21.68 7.97 21.21L6.24 20.22C5.33 19.7 5.02 18.53 5.54 17.63C6.45 16.06 5.71 14.78 3.9 14.78C2.85 14.78 2 13.92 2 12.88Z"
                    stroke="currentColor"
                  />
                </svg>
              </span>
              <span class="pcoded-mtext">{{ $t('message.settings') }}</span>
            </router-link>
          </li>
        </ul>
        <p class="text-center px-2 pb-2 mt-4">Qollega v3.9.1</p>
      </div>
    </div>
  </nav>
</template>

<script>
import Logo from '@/assets/images/qollega_logo_normal.png';
import { mapGetters } from 'vuex';
import { can_access } from '@/utils/permission';

export default {
  props: ['is_sidebar_open'],
  name: 'SidenavComponent',
  data: function () {
    return {
      logo: Logo,
      isSidebarOpen: false,
      routeList: this.$router.options.routes,
      isDisableMenu: false,
    };
  },
  computed: {
    ...mapGetters(['get_user_data']),
    currentRoute() {
      return this.$route.name;
    },
  },
  watch: {
    is_sidebar_open(value) {
      this.isSidebarOpen = value;
    },
  },
  methods: {
    can_access,
    pos() {
      window.location.href = '/pos';
    },
    is_route_match(parent_route = null, route_name) {
      if (parent_route !== null) {
        return this.routeList
          .filter((route) => {
            return route.name === parent_route;
          })[0]
          .children.some((child) => child.name === route_name);
      }
    },
  },
  created() {
    setTimeout(() => {
      let statusAccount = JSON.parse(
        localStorage.getItem('account_status')
      )?.status;

      if (statusAccount === 'Akun Kadaluarsa') {
        this.isDisableMenu = true;
      }
    }, 1500);
  },
};
</script>

<style scoped>
.router-link {
  margin: 0 !important;
}

svg {
  stroke-width: 1.5;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  fill: none;
}

@media (min-width: 991px) {
  .mobile-menu {
    visibility: hidden;
  }
}

.navbar-wrapper::-webkit-scrollbar {
  width: 6px;
}

.navbar-wrapper::-webkit-scrollbar-track {
  background: #ffffff;
}

.navbar-wrapper::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
}

.navbar-wrapper::-webkit-scrollbar-thumb:hover {
  background: #c5c5c5;
}
</style>
