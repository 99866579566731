import Vue from 'vue';
import App from './App.vue';

// ** Vuex store
import store from '@/store';

// ** Vue localization
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import messages from '@/localization';
const i18n = new VueI18n({
  locale: 'id',
  messages, // set locale messages
});

// ** Routes
import VueRouter from 'vue-router';
import routes from './routes';
Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes,
});

// ** Vee validate
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// ** Vuesax
import Vuesax from 'vuesax';
import 'vuesax/dist/vuesax.css';
Vue.use(Vuesax);

// ** Apex chart
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('ApexChart', VueApexCharts);

// ** Datatables
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/mdc-light-deeppurple/theme.css';
import 'primeicons/primeicons.css';
Vue.use(PrimeVue);
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
Vue.component('DataTable', DataTable);
// eslint-disable-next-line
Vue.component('Column', Column);

// ** Multiselect
import MultiSelect from 'primevue/multiselect';
Vue.component('MultiSelect', MultiSelect);

// ** Dropdown
import Dropdown from 'primevue/dropdown';
// eslint-disable-next-line
Vue.component('Dropdown', Dropdown);

// ** Dialog
import Dialog from 'primevue/dialog';
// eslint-disable-next-line
Vue.component('Dialog', Dialog);

// ** Datetime picker
import Calendar from 'primevue/calendar';
// eslint-disable-next-line
Vue.component('Calendar', Calendar);

// ** Input Text
import InputText from 'primevue/inputtext';
Vue.component('InputText', InputText);

// ** Sidebar
import Sidebar from 'primevue/sidebar';
// eslint-disable-next-line
Vue.component('Sidebar', Sidebar);

// ** Chips
import Chips from 'primevue/chips';
// eslint-disable-next-line
Vue.component('Chips', Chips);

// ** Button
import Button from 'primevue/button';
// eslint-disable-next-line
Vue.component('Button', Button);

// ** Steps
import Steps from 'primevue/steps';
// eslint-disable-next-line
Vue.component('Steps', Steps);

// ** Inplace
import Inplace from 'primevue/inplace';
// eslint-disable-next-line
Vue.component('Inplace', Inplace);

// ** Autocomplete
import AutoComplete from 'primevue/autocomplete';
Vue.component('AutoComplete', AutoComplete);

// ** Accordion
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Accordion', Accordion);
Vue.component('AccordionTab', AccordionTab);

// ** RadioButton
import RadioButton from 'primevue/radiobutton';
Vue.component('RadioButton', RadioButton);

// ** Vue Meta
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

// ** Vue Progressbar
import VueProgressBar from 'vue-progressbar';
Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '2px',
});

// ** Toast
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
const toastOptions = {
  position: 'top-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
};
Vue.use(Toast, toastOptions);

// ** Sweetalert
import Swal from 'sweetalert2';
Vue.prototype.$Swal = Swal;

// ** Axios
import Axios from 'axios';
Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      if (error.response.data.message.includes('habis')) {
        window.location.href = '/expired';
      } else {
        // window.location.href = '/login';
      }
      localStorage.clear();
    }
  }
);
Vue.prototype.Axios = Axios;

Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_ENVIRONTMENT === 'development';
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
