const backofficeRoutes = [
  {
    name: 'dashboard',
    path: '/',
    component: () => import('../pages/dashboard'),
  },
  {
    name: 'testing',
    path: '/testing-json',
    component: () => import('../../latency-test'),
  },
  {
    name: 'testing_mockapi',
    path: '/testing-mockapi',
    component: () => import('../../latency-test/mockapi.vue'),
  },
  {
    name: 'transaction',
    path: '/transaction',
    redirect: 'transaction/list',
    component: () => import('../pages/transaction'),
    children: [
      {
        name: 'list_transaction',
        path: 'list',
        component: () => import('../pages/transaction/list_transaction'),
      },
    ],
  },
  {
    name: 'product',
    path: '/product',
    redirect: 'product_list',
    component: () => import('../pages/product'),
    children: [
      {
        name: 'product_list',
        path: '/product_list',
        redirect: '/product_list/list',
        components: {
          product_list: () => import('../pages/product/product_list'),
        },
        children: [
          {
            name: 'index_product_list',
            path: 'list',
            component: () => import('../pages/product/product_list/List.vue'),
          },
          {
            name: 'add_product',
            path: 'add',
            component: () =>
              import('../pages/product/product_list/AddProduct.vue'),
          },
          {
            name: 'edit_product',
            path: 'edit/:id',
            component: () =>
              import('../pages/product/product_list/EditProduct.vue'),
          },
          {
            name: 'detail_product',
            path: 'detail/:id',
            component: () =>
              import('../pages/product/product_list/DetailProduct.vue'),
          },
          {
            name: 'import_product',
            path: 'import_product',
            component: () =>
              import('../pages/product/product_list/ImportProduct.vue'),
          },
        ],
      },
      {
        name: 'barcode',
        path: 'barcode',
        components: {
          barcode: () => import('../pages/product/barcode'),
        },
      },
      {
        name: 'promosi',
        path: 'promosi',
        components: {
          promosi: () => import('../pages/product/promotion/comp'),
        },
      },
      {
        name: 'pengaturan',
        path: 'pengaturan',
        components: {
          pengaturan: () => import('../pages/product/product_category'),
        },
      },
    ],
  },
  {
    name: 'inventory',
    path: '/inventory',
    redirect: 'daftar_stok',
    component: () => import('../pages/inventory'),
    children: [
      {
        name: 'daftar_stok',
        path: '/daftar_stok',
        redirect: '/daftar_stok/list',
        components: {
          daftar_stok: () => import('../pages/inventory/tabs/list_stock'),
        },
        children: [
          {
            name: 'list_stok',
            path: 'list',
            component: () =>
              import('../pages/inventory/tabs/list_stock/AllStock.vue'),
          },
          {
            name: 'riwayat_perubahan',
            path: 'riwayat_perubahan/:id',
            component: () =>
              import('../pages/inventory/tabs/list_stock/StockHistory.vue'),
          },
        ],
      },
      {
        name: 'stok_keluar',
        path: '/stok_keluar',
        redirect: '/stok_keluar/list',
        components: {
          stok_keluar: () => import('../pages/inventory/tabs/stock_out'),
        },
        children: [
          {
            name: 'list_stok_keluar',
            path: 'list',
            component: () =>
              import('../pages/inventory/tabs/stock_out/ListStockOut.vue'),
          },
          {
            name: 'detail_mark_out',
            path: 'detail_mark_out/:id',
            component: () =>
              import(
                '../pages/inventory/tabs/stock_out/mark_out/DetailMarkOut.vue'
              ),
          },
          {
            name: 'detail_transfer_out',
            path: 'detail_transfer/:id',
            component: () =>
              import(
                '../pages/inventory/tabs/stock_out/transfer_out/DetailTransferOut.vue'
              ),
          },
          {
            name: 'kurangi_manual',
            path: 'kurangi_manual/:method/:stockOut',
            component: () =>
              import(
                '../pages/inventory/tabs/stock_out/mark_out/ManualReduction.vue'
              ),
          },
          {
            name: 'transfer_keluar',
            path: 'transfer_keluar/:method/:stockOut',
            component: () =>
              import(
                '../pages/inventory/tabs/stock_out/transfer_out/TransferOut.vue'
              ),
          },
        ],
      },
      {
        name: 'stok_masuk',
        path: '/stok_masuk',
        redirect: '/stok_masuk/list',
        components: {
          stok_masuk: () => import('../pages/inventory/tabs/stock_masuk'),
        },
        children: [
          {
            name: 'list_stok_masuk',
            path: 'list',
            component: () =>
              import('../pages/inventory/tabs/stock_masuk/ListStockIn.vue'),
          },
          {
            name: 'tambah_manual',
            path: 'tambah_manual/:method/:nomor',
            component: () =>
              import(
                '../pages/inventory/tabs/stock_masuk/mark_in/CreateStockIn.vue'
              ),
          },
          {
            name: 'detail_stok_masuk',
            path: 'detail/:id',
            component: () =>
              import(
                '../pages/inventory/tabs/stock_masuk/mark_in/DetailStockIn.vue'
              ),
          },
          {
            name: 'detail_transfer_in',
            path: 'detail/:id',
            component: () =>
              import(
                '../pages/inventory/tabs/stock_masuk/transfer_in/DetailTransferIn.vue'
              ),
          },
          {
            name: 'accept_transfer',
            path: 'accept_transfer/:id',
            component: () =>
              import(
                '../pages/inventory/tabs/stock_masuk/transfer_in/AcceptTransferStock.vue'
              ),
          },
        ],
      },
      {
        name: 'stok_opname',
        path: '/stok_opname',
        redirect: '/stok_opname/list',
        components: {
          stok_opname: () => import('../pages/inventory/tabs/stock_opname'),
        },
        children: [
          {
            name: 'list_stok_opname',
            path: 'list',
            component: () =>
              import(
                '../pages/inventory/tabs/stock_opname/ListStockOpname.vue'
              ),
          },
          {
            name: 'opname_manual',
            path: 'opname_manual/:method/:nomor',
            component: () =>
              import(
                '../pages/inventory/tabs/stock_opname/opname_manual/OpnameManual.vue'
              ),
          },
          {
            name: 'detail_opname',
            path: 'detail_opname/:id',
            component: () =>
              import('../pages/inventory/tabs/stock_opname/DetailOpname.vue'),
          },
          {
            name: 'import_opname',
            path: 'import_opname',
            component: () =>
              import(
                '../pages/inventory/tabs/stock_opname/import_opname/ImportOpname.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    name: 'promotion',
    path: '/promotion',
    component: () => import('../pages/product/promotion'),
  },
  {
    name: 'reports',
    path: '/reports',
    component: () => import('../pages/reports'),
    redirect: '/reports/penjualan',
    children: [
      {
        name: 'penjualan',
        path: 'penjualan',
        components: {
          report_penjualan: () => import('@/index/pages/reports/penjualan'),
        },
      },
      {
        name: 'metode_bayar',
        path: 'metode_bayar',
        components: {
          report_metode_bayar: () =>
            import('@/index/pages/reports/metode_bayar'),
        },
      },
      {
        name: 'kasir',
        path: 'kasir',
        redirect: 'kasir/list_kasir',
        components: {
          report_kasir: () => import('@/index/pages/reports/kasir'),
        },
        children: [
          {
            name: 'list_kasir',
            path: 'list_kasir',
            component: () => import('@/index/pages/reports/kasir/list'),
          },
          {
            name: 'detail_kasir',
            path: 'detail_kasir/:id',
            component: () => import('@/index/pages/reports/kasir/detail'),
          },
        ],
      },
      {
        name: 'akumulasi_shift',
        path: 'akumulasi_shift',
        redirect: 'akumulasi_shift/list_shift',
        components: {
          report_shift: () => import('@/index/pages/reports/akumulasi_shift'),
        },
        children: [
          {
            name: 'list_shift',
            path: 'list_shift',
            component: () =>
              import('@/index/pages/reports/akumulasi_shift/list'),
          },
          {
            name: 'detail_shift',
            path: 'detail_shift/:date',
            component: () =>
              import('@/index/pages/reports/akumulasi_shift/detail'),
          },
        ],
      },
      {
        name: 'utang',
        path: 'utang',
        redirect: 'utang/list_utang',
        components: {
          report_utang: () => import('@/index/pages/reports/utang'),
        },
        children: [
          {
            name: 'list_utang',
            path: 'list_utang',
            component: () => import('@/index/pages/reports/utang/list'),
          },
        ],
      },
      {
        name: 'laba_rugi',
        path: 'laba_rugi',
        components: {
          report_laba_rugi: () => import('@/index/pages/reports/laba_rugi'),
        },
      },
      {
        name: 'retur_penjualan',
        path: 'retur_penjualan',
        components: {
          report_retur_penjualan: () =>
            import('@/index/pages/reports/retur_penjualan'),
        },
      },
      {
        name: 'pelanggan',
        path: 'pelanggan',
        components: {
          report_pelanggan: () => import('@/index/pages/reports/pelanggan'),
        },
      },
      {
        name: 'produk',
        path: 'produk',
        redirect: 'produk/list_produk',
        components: {
          report_produk: () => import('@/index/pages/reports/produk'),
        },
        children: [
          {
            name: 'list_produk',
            path: 'list_produk',
            component: () => import('@/index/pages/reports/produk/list'),
          },
          {
            name: 'detail_produk',
            path: 'detail_produk/:productId/:variantId',
            component: () => import('@/index/pages/reports/produk/detail'),
          },
        ],
      },
      {
        name: 'pajak',
        path: 'pajak',
        components: {
          report_pajak: () => import('@/index/pages/reports/pajak'),
        },
      },
    ],
  },
  {
    name: 'operasional',
    path: '/operasional',
    component: () => import('../pages/operasional'),
  },
  {
    name: 'staff',
    path: '/staff',
    redirect: 'staff/list',
    component: () => import('../pages/staff'),
    children: [
      {
        path: 'list',
        name: 'staff_list',
        components: {
          staff_list: () => import('../pages/staff/staff_list'),
        },
      },
      {
        path: 'presensi',
        name: 'presensi',
        components: {
          presensi: () => import('../pages/staff/presensi'),
        },
      },
    ],
  },
  {
    name: 'suppliers',
    path: '/suppliers',
    redirect: 'supplier_list',
    component: () => import('../pages/suppliers'),
    children: [
      {
        name: 'supplier_list',
        path: '/supplier_list',
        components: {
          supplier_list: () => import('../pages/suppliers/supplier'),
        },
      },
      {
        name: 'purchase_list',
        path: '/purchase_list',
        redirect: '/purchase_list/list',
        components: {
          purchase_list: () => import('../pages/suppliers/product_purchase'),
        },
        children: [
          {
            name: 'list_purchase_product',
            path: 'list',
            component: () =>
              import(
                '@/index/pages/suppliers/product_purchase/ListPurchaseProduct.vue'
              ),
          },
          {
            name: 'purchase_order',
            path: 'purchase_order/:method/:nomor',
            component: () =>
              import(
                '@/index/pages/suppliers/product_purchase/CreatePurchaseOrder.vue'
              ),
          },
          {
            name: 'detail_purchase_order',
            path: 'detail_purchase_order/:id',
            component: () =>
              import(
                '@/index/pages/suppliers/product_purchase/DetailPurchaseOrder.vue'
              ),
          },
        ],
      },
      {
        name: 'product_retur',
        path: '/product_retur',
        redirect: '/product_retur/list',
        components: {
          product_retur: () => import('../pages/suppliers/product_retur'),
        },
        children: [
          {
            name: 'list_retur_product',
            path: 'list',
            component: () =>
              import(
                '@/index/pages/suppliers/product_retur/ListReturProduct.vue'
              ),
          },
          {
            name: 'create_retur',
            path: 'create_retur/:method/:nomor',
            component: () =>
              import(
                '@/index/pages/suppliers/product_retur/CreateReturProduct.vue'
              ),
          },
          {
            name: 'detail_retur',
            path: 'detail_retur/:id',
            component: () =>
              import(
                '@/index/pages/suppliers/product_retur/DetailReturProduct.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    name: 'customers',
    path: '/customers',
    component: () => import('../pages/customers'),
  },
  {
    name: 'settings',
    path: '/settings',
    component: () => import('../pages/settings'),
    redirect: '/settings/tipe_pesanan',
    children: [
      {
        path: 'struk',
        name: 'struk',
        components: {
          struk: () => import('../pages/settings/tabs/struk'),
        },
      },
      {
        path: 'tipe_pesanan',
        name: 'tipe_pesanan',
        components: {
          tipe_pesanan: () => import('../pages/settings/tabs/tipe_pesanan'),
        },
      },
      {
        name: 'metode_pembayaran',
        path: '/metode_pembayaran',
        redirect: '/metode_pembayaran/daftar_metode',
        components: {
          metode_pembayaran: () =>
            import('../pages/settings/tabs/metode_pembayaran'),
        },
        children: [
          {
            name: 'daftar_metode',
            path: 'daftar_metode',
            component: () =>
              import(
                '../pages/settings/tabs/metode_pembayaran/PaymentMethod.vue'
              ),
          },
          {
            name: 'riwayat_transaksi',
            path: 'riwayat_transaksi',
            component: () =>
              import(
                '../pages/settings/tabs/metode_pembayaran/qris/TransactionHistory.vue'
              ),
          },
        ],
      },
      {
        path: 'pengaturan_pajak',
        name: 'pengaturan_pajak',
        components: {
          pajak: () => import('../pages/settings/tabs/pajak'),
        },
      },
    ],
  },
  {
    name: 'profile',
    path: '/profile',
    redirect: 'profile/profil_akun',
    component: () => import('../pages/profile'),
    children: [
      {
        path: 'profil_akun',
        name: 'account_profile',
        components: {
          account_profile: () => import('../pages/profile/account_profile'),
        },
      },
      {
        path: 'profil_bisnis',
        name: 'business_profile',
        components: {
          business_profile: () => import('../pages/profile/business_profile'),
        },
      },
      {
        name: 'informasi_akun',
        path: '/informasi_akun',
        redirect: '/informasi_akun/masa_aktif',
        components: {
          informasi_akun: () => import('../pages/profile/account_purchase'),
        },
        children: [
          {
            path: 'masa_aktif',
            name: 'masa_aktif',
            component: () =>
              import(
                '../pages/profile/account_purchase/AccountInformation.vue'
              ),
          },
          {
            path: 'pembelian_akun',
            name: 'pembelian_akun',
            component: () =>
              import(
                '../pages/profile/account_purchase/SubscriptionPurchase.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    name: 'table_management',
    path: '/table_management',
    redirect: 'table_management/list',
    component: () => import('../pages/table_management'),
    children: [
      {
        name: 'list_area',
        path: 'list',
        component: () => import('../pages/table_management/area/ListArea.vue'),
      },
      {
        name: 'print_qr',
        path: 'print_qr/:id',
        component: () => import('../pages/table_management/PrintQR.vue'),
      },
      {
        name: 'detail_area',
        path: 'detail/:id',
        component: () =>
          import('../pages/table_management/table/DetailArea.vue'),
      },
    ],
  },
  {
    path: '/pembelian_akun/invoice/:invoiceId',
    name: 'subscription_invoice',
    component: () => import('@/index/components/SubscriptionInvoice.vue'),
  },
  {
    path: '/pembelian_akun/transaksi_berhasil',
    name: 'subscription_success',
    component: () => import('@/index/components/SubscriptionSuccess.vue'),
  },
  {
    path: '*', // Handling all of unmatch routes,
    name: 'not_found',
    component: () => import('@/global_components/NotFound.vue'),
  },
];
export default backofficeRoutes;
